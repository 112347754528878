import { LoadingButton, ModalStatus, Text, useModalState } from "@clipboard-health/ui-react";
import { Button, Stack } from "@mui/material";
import { CommonDialog } from "@src/appV2/lib/Dialogs/Common/Dialog";

interface ErrorDialogProps {
  errorText: string;
  canRetryUpload: boolean;
  isLoading: boolean;
  onRetry: () => void | undefined;
  onClose: () => void | undefined;
}

export function VideoUploadErrorDialog(props: ErrorDialogProps) {
  const { errorText, isLoading, onRetry, canRetryUpload, onClose } = props;
  const modalState = useModalState(ModalStatus.OPEN);

  return (
    <CommonDialog
      modalState={modalState}
      title="Error submitting video"
      actions={
        <Stack spacing={1} alignItems="stretch" width="100%">
          <LoadingButton
            fullWidth
            variant="contained"
            isLoading={isLoading}
            disabled={isLoading}
            onClick={onRetry}
          >
            Retry{canRetryUpload ? " upload" : ""}
          </LoadingButton>
          <Button fullWidth onClick={onClose}>
            Cancel
          </Button>
        </Stack>
      }
      onClose={onClose}
    >
      <Text> {errorText} </Text>
    </CommonDialog>
  );
}
