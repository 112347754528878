import { Image, ModalStatus, Span, Text, useModalState } from "@clipboard-health/ui-react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from "@mui/material";
import { isAndroidPlatform } from "@src/appV2/lib";
import {
  APP_V2_APP_EVENTS,
  APP_V2_USER_EVENTS,
  logError,
  logEvent,
} from "@src/appV2/lib/analytics";
import blackPixelImage from "public/assets/images/black-pixel.png";
import transparentPixelImage from "public/assets/images/transparent-pixel.png";
import { useCallback, useEffect, useRef, useState } from "react";

import { type HcfTimeclockComplianceVideoConfig } from "../../ShiftState/types";
import { ClockActionPictureDialogEvent } from "../ClockActionPictureDialogEvent";
import startRecordingButtonImage from "../images/start_recording_button.svg";
import stopRecordingButtonImage from "../images/stop_recording_button.svg";
import { ConfirmClockVideoDeletionDialog } from "./ConfirmClockVideoDeletionDialog";
import { useVideoRecorder } from "./useVideoRecorder";

interface RecordVideoDialogProps {
  videoConfig: HcfTimeclockComplianceVideoConfig | undefined;
  logData: Record<string, unknown>;
  onClose: () => void;
  videoConfirmedCallback: (blob: Blob) => void;
}

const isAndroid = isAndroidPlatform();

export function RecordVideoDialog(props: RecordVideoDialogProps) {
  const { videoConfig, logData, onClose, videoConfirmedCallback } = props;
  const modalState = useModalState(ModalStatus.OPEN);
  const confirmDeletionModalState = useModalState(ModalStatus.CLOSED);
  const [videoPreviewElementRef, setVideoPreviewElementRef] = useState<HTMLVideoElement>();
  const [recordedVideoUrl, setRecordedVideoUrl] = useState<string>();
  const [recordedVideoElementRef, setRecordedVideoElementRef] = useState<HTMLVideoElement>();
  const videoBlobRef = useRef<Blob>();

  // Required to load the preview frame before the video plays
  useEffect(() => {
    recordedVideoElementRef?.load();
  }, [recordedVideoElementRef]);

  const onVideoRecorded = useCallback((blob: Blob) => {
    const url = URL.createObjectURL(blob);
    setRecordedVideoUrl(url);
    videoBlobRef.current = blob;
  }, []);

  const onVideoRecordedError = useCallback(
    (error: unknown) => {
      logError(APP_V2_APP_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_ERROR, {
        error,
        metadata: { logData, location: "startRecording" },
      });
    },
    [logData]
  );

  const { startRecording, stopRecording, isReady, isRecording, recordingTime } = useVideoRecorder({
    videoPreviewRef: videoPreviewElementRef,
    onVideoRecorded,
    onError: onVideoRecordedError,
    maxRecordingTime: videoConfig?.maxVideoLength ?? 60,
    resolution: {
      width: videoConfig?.videoWidth ?? 414,
      height: videoConfig?.videoHeight ?? 736,
    },
    videoBitsPerSecond: videoConfig?.videoBitRate,
  });

  return (
    <>
      {recordedVideoUrl ? (
        <Dialog fullScreen open={modalState.modalIsOpen}>
          <DialogTitle mt={2} mb={1}>
            Looks good?
          </DialogTitle>
          <DialogContent>
            <Stack spacing={2} display="flex" textAlign="center">
              <Text>
                This video will be reviewed by facility admin.
                <br />
                <Span sx={{ color: (theme) => theme.palette.error.main }}>
                  Repeated failure to use the time clock may result in DNRs or pay reversals.
                </Span>
              </Text>
              <Box>
                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <video
                  ref={(ref) => {
                    setRecordedVideoElementRef(ref ?? undefined);
                  }}
                  controls
                  playsInline
                  disablePictureInPicture
                  disableRemotePlayback
                  preload="auto"
                  // To force android to load a poster image
                  src={isAndroid ? `${recordedVideoUrl}#t=0.1` : recordedVideoUrl}
                  height="100%"
                  style={{
                    background: "black",
                    maxWidth: "100%",
                    maxHeight: "55vh",
                    display: "block",
                    aspectRatio: "9 / 16",
                    margin: "auto",
                    objectFit: "contain",
                    borderRadius: "16px",
                  }}
                  // To prevent android from showing askewed play icon until video loads
                  poster={isAndroid ? blackPixelImage : undefined}
                  onLoadedData={(event) => {
                    if (!event.currentTarget.hasAttribute("poster")) {
                      event.currentTarget.removeAttribute("poster");
                    }
                  }}
                />
              </Box>
            </Stack>
          </DialogContent>
          <DialogActions sx={{ paddingBottom: 1 }}>
            <Stack spacing={1} direction="column" width="100%">
              <Button
                fullWidth
                variant="contained"
                onClick={async () => {
                  videoConfirmedCallback(videoBlobRef.current!);
                }}
              >
                Looks great! Submit video
              </Button>
              <Button fullWidth variant="text" onClick={confirmDeletionModalState.openModal}>
                Try again
              </Button>
            </Stack>
          </DialogActions>
        </Dialog>
      ) : (
        <Dialog
          fullScreen
          open={modalState.modalIsOpen}
          sx={{ background: "black" }}
          PaperProps={{ sx: { backgroundColor: "black" } }}
        >
          <DialogContent sx={{ display: "flex", position: "relative", padding: 0 }}>
            <video
              ref={(ref) => {
                setVideoPreviewElementRef(ref ?? undefined);
              }}
              playsInline
              autoPlay
              muted
              style={{
                background: "black",
                objectFit: "contain",
                width: "100%",
                height: "100%",
              }}
              // To prevent android from showing askewed play icon until video loads
              poster={transparentPixelImage}
            />
            <IconButton
              disableRipple
              sx={{ position: "absolute", top: "2vh", left: "1.5vh" }}
              title="Go back"
              onClick={() => {
                logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
                  ...logData,
                  recordingTime,
                  isRecording,
                  eventAction: ClockActionPictureDialogEvent.RECORDING_CANCEL,
                });

                onClose();
              }}
            >
              <ArrowBackIcon sx={{ fontSize: "4vh", color: "white" }} />
            </IconButton>
            <Stack
              spacing={0}
              sx={{
                position: "absolute",
                bottom: "3vh",
                left: 0,
                width: "100%",
                alignItems: "center",
              }}
            >
              <Text color="white">0:{recordingTime?.toString().padStart(2, "0") ?? "00"}</Text>

              <IconButton
                disableRipple
                title={isRecording ? "Stop recording" : "Start recording"}
                onClick={() => {
                  if (!isReady || recordedVideoUrl) {
                    return;
                  }

                  if (isRecording) {
                    void stopRecording();
                    logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
                      ...logData,
                      recordingTime,
                      eventAction: ClockActionPictureDialogEvent.RECORDING_DONE,
                    });
                    return;
                  }

                  void startRecording();
                  logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
                    ...logData,
                    eventAction: ClockActionPictureDialogEvent.RECORDING_START,
                  });
                }}
              >
                <Image
                  src={isRecording ? stopRecordingButtonImage : startRecordingButtonImage}
                  alt={isRecording ? "Stop recording" : "Start recording"}
                  width="8vh"
                  height="8vh"
                />
              </IconButton>
            </Stack>
          </DialogContent>
        </Dialog>
      )}
      <ConfirmClockVideoDeletionDialog
        modalState={confirmDeletionModalState}
        onContinue={() => {
          setRecordedVideoUrl(undefined);
          confirmDeletionModalState.closeModal();
        }}
        onCancel={() => {
          confirmDeletionModalState.closeModal();
        }}
      />
    </>
  );
}
