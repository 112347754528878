import { Diagnostic } from "@ionic-native/diagnostic";
import { APP_V2_USER_EVENTS, isCapacitorPlatform } from "@src/appV2/lib";
import { logEvent } from "@src/appV2/lib/analytics";

import { ClockActionPictureDialogEvent } from "./ClockActionPictureDialogEvent";

async function getMicCameraStatuses() {
  const microphoneStatus = (await Diagnostic.getMicrophoneAuthorizationStatus()) as string;
  const cameraStatus = (await Diagnostic.getCameraAuthorizationStatus(false)) as string;

  return { microphoneStatus, cameraStatus };
}

// TODO(TX-2186): refactor this for reusability
export async function getPermissions(): Promise<{ camera: boolean; microphone: boolean }> {
  if (!isCapacitorPlatform()) {
    return { camera: true, microphone: true };
  }

  const NOT_REQUESTED_PERMISSION_STATUSES = new Set([Diagnostic.permissionStatus.NOT_REQUESTED]);
  const ALLOWED_PERMISSION_STATUSES = new Set([
    Diagnostic.permissionStatus.GRANTED,
    Diagnostic.permissionStatus.GRANTED_WHEN_IN_USE,
  ]);

  let { microphoneStatus, cameraStatus } = await getMicCameraStatuses();

  if (
    ALLOWED_PERMISSION_STATUSES.has(microphoneStatus) &&
    ALLOWED_PERMISSION_STATUSES.has(cameraStatus)
  ) {
    return { camera: true, microphone: true };
  }

  if (NOT_REQUESTED_PERMISSION_STATUSES.has(microphoneStatus)) {
    await Diagnostic.requestMicrophoneAuthorization();
  }

  if (NOT_REQUESTED_PERMISSION_STATUSES.has(cameraStatus)) {
    await Diagnostic.requestCameraAuthorization(true);
  }

  ({ microphoneStatus, cameraStatus } = await getMicCameraStatuses());
  return {
    camera: ALLOWED_PERMISSION_STATUSES.has(cameraStatus),
    microphone: ALLOWED_PERMISSION_STATUSES.has(microphoneStatus),
  };
}

export async function uploadTimeclockProofVideoToS3(props: {
  uploadUrl: string;
  videoBlob: Blob;
  logData: Record<string, unknown>;
}): Promise<{ isSuccess: boolean }> {
  const { uploadUrl, videoBlob, logData } = props;
  const uploadStartTime = Date.now();

  try {
    const fetchResponse = await fetch(uploadUrl, { method: "PUT", body: videoBlob });

    if (!fetchResponse.ok) {
      throw new Error(`Failed to upload video to S3: ${fetchResponse.statusText}`);
    }

    logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
      ...logData,
      uploadDurationMs: Date.now() - uploadStartTime,
      eventAction: ClockActionPictureDialogEvent.UPLOAD_SUCCESS,
    });
  } catch (error) {
    logEvent(APP_V2_USER_EVENTS.FACILITY_TIMECLOCK_COMPLIANCE_EVENT, {
      ...logData,
      uploadDurationMs: Date.now() - uploadStartTime,
      eventAction: ClockActionPictureDialogEvent.UPLOAD_FAILURE,
      source: "s3-upload",
      error: error instanceof Error ? error.message : "Unknown error",
    });

    return { isSuccess: false };
  }

  return { isSuccess: true };
}
