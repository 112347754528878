import { useCallback, useEffect, useRef, useState } from "react";

export interface UseVideoRecorderProps {
  videoPreviewRef: HTMLVideoElement | undefined;
  onVideoRecorded: (blob: Blob) => void;
  onError: (error: unknown) => void;

  // Defaults to 414x736 (9:16, portrait)
  resolution?: { width: number; height: number };
  // Optioanl camera direction, defaults to "environment" (back camera)
  facingMode?: "user" | "environment";
  // Optional max recording time in seconds
  maxRecordingTime?: number;
  // Optional video bitrate in bits per second
  videoBitsPerSecond?: number;
  // Optional audio bitrate in bits per second
  audioBitsPerSecond?: number;
}

const ENCODINGS_LISTS = ["video/mp4", "video/webm"] as const;

const SUPPORTED_ENCODING = ENCODINGS_LISTS.find((encoding) =>
  MediaRecorder.isTypeSupported(encoding)
);

export function useVideoRecorder(props: UseVideoRecorderProps) {
  const {
    videoPreviewRef,
    onVideoRecorded,
    onError,
    resolution = { width: 414, height: 736 },
    videoBitsPerSecond,
    audioBitsPerSecond,
    facingMode = "environment",
    maxRecordingTime,
  } = props;

  const [isReady, setIsReady] = useState<boolean>(false);
  const [isRecording, setIsRecording] = useState<boolean>(false);
  const [recordingTime, setRecordingTime] = useState<number>();

  const streamRef = useRef<MediaStream>();
  const mediaRecorderRef = useRef<MediaRecorder>();
  const blobs = useRef<Blob[]>([]);
  const recordingIntervalRef = useRef<NodeJS.Timeout>();
  const maxRecordingTimeoutRef = useRef<NodeJS.Timeout>();

  const resetRecordingTimers = useCallback(() => {
    if (recordingIntervalRef.current) {
      clearInterval(recordingIntervalRef.current);
      recordingIntervalRef.current = undefined;
    }

    if (maxRecordingTimeoutRef.current) {
      clearTimeout(maxRecordingTimeoutRef.current);
      maxRecordingTimeoutRef.current = undefined;
    }
  }, []);

  const destroy = useCallback(async () => {
    resetRecordingTimers();

    if (!streamRef.current) {
      return;
    }

    mediaRecorderRef.current?.stop();
    mediaRecorderRef.current = undefined;

    streamRef.current?.getTracks().forEach((track) => {
      track.stop();
    });

    streamRef.current = undefined;
    setIsReady(false);
  }, [resetRecordingTimers]);

  const initialise = useCallback(async () => {
    if (streamRef.current) {
      return;
    }

    if (!videoPreviewRef) {
      return;
    }

    // Width and height are swapped when screen is in portrait mode
    const isScreenPortrait = screen.availHeight > screen.availWidth;
    const stream = await navigator.mediaDevices.getUserMedia({
      video: {
        width: isScreenPortrait ? resolution.height : resolution.width,
        height: isScreenPortrait ? resolution.width : resolution.height,
        facingMode: { ideal: facingMode },
      },
      audio: {},
    });

    videoPreviewRef.srcObject = stream;
    streamRef.current = stream;
    setIsReady(true);
  }, [facingMode, resolution.height, resolution.width, videoPreviewRef]);

  const stopRecording = useCallback(async () => {
    const mediaRecorder = mediaRecorderRef.current;
    if (!mediaRecorder) {
      return;
    }

    mediaRecorder.stop();
    mediaRecorderRef.current = undefined;

    resetRecordingTimers();
    setIsRecording(false);
    setRecordingTime(undefined);
  }, [resetRecordingTimers]);

  const startRecording = useCallback(async () => {
    if (!streamRef.current || !isReady) {
      return;
    }

    let mediaRecorder: MediaRecorder;
    try {
      mediaRecorder = new MediaRecorder(streamRef.current, {
        mimeType: SUPPORTED_ENCODING,
        videoBitsPerSecond,
        audioBitsPerSecond,
      });
    } catch (error: unknown) {
      onError(error);
      return;
    }

    mediaRecorderRef.current = mediaRecorder;
    mediaRecorder.ondataavailable = (blobEvent) => {
      blobs.current.push(blobEvent.data);
    };

    mediaRecorder.onstop = () => {
      const blob = new Blob(blobs.current, { type: SUPPORTED_ENCODING ?? "video/mp4" });
      blobs.current = [];

      onVideoRecorded(blob);

      resetRecordingTimers();
      setIsRecording(false);
      setRecordingTime(undefined);
    };

    mediaRecorder.onstart = () => {
      recordingIntervalRef.current = setInterval(() => {
        setRecordingTime((previousTime) => (previousTime ?? 0) + 1);
      }, 1000);

      if (maxRecordingTime) {
        maxRecordingTimeoutRef.current = setTimeout(() => {
          void stopRecording();
        }, maxRecordingTime * 1000);
      }
    };

    mediaRecorder.start();

    setIsRecording(true);
    setRecordingTime(0);
  }, [
    isReady,
    maxRecordingTime,
    videoBitsPerSecond,
    audioBitsPerSecond,
    onError,
    onVideoRecorded,
    resetRecordingTimers,
    stopRecording,
  ]);

  useEffect(() => {
    if (videoPreviewRef) {
      void initialise();
    }

    return () => {
      void destroy();
    };
  }, [videoPreviewRef, initialise, destroy]);

  return {
    startRecording,
    stopRecording,
    isReady,
    isRecording,
    recordingTime,
  };
}
