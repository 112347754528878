import { Image, ModalStatus, Text, Title, useModalState } from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

import timeclockBiometricComplianceImage from "../images/timeclock_biometric_compliance.png";
import { TimeclockRecordingHelpDetailsDialog } from "./HelpDetailsDialog";

interface TimeclockComplianceVideoPolicyDialogProps {
  onContinue: (source: "main-dialog" | "help-dialog") => void;
  onCancel: () => void;
  facilityName: string;
  clockAction: "clock-in" | "clock-out";
  timeclockLocation: string | undefined;
  timeclockPin: string | undefined;
}

export function TimeclockComplianceVideoPolicyDialog(
  props: TimeclockComplianceVideoPolicyDialogProps
) {
  const { onContinue, onCancel, facilityName, clockAction, timeclockLocation, timeclockPin } =
    props;

  const modalState = useModalState(ModalStatus.OPEN);
  const helpModalState = useModalState(ModalStatus.CLOSED);

  return (
    <>
      <Dialog fullScreen open={modalState.modalIsOpen} onClose={onCancel}>
        <DialogTitle mt={2} mb={1} textAlign="left">
          Use the facility time clock
        </DialogTitle>
        <DialogContent>
          <Stack spacing={2} alignItems="stretch">
            <Image
              src={timeclockBiometricComplianceImage}
              alt="Time clock biometric compliance"
              width="100%"
            />
            <Text>
              You must record a video of yourself using the facility&apos;s time clock before you
              can proceed with in-app clock {clockAction === "clock-in" ? "in" : "out"}.
            </Text>
            <Text>
              {timeclockLocation
                ? `${facilityName} has a time clock located ${timeclockLocation}.`
                : `Please ask the staff at ${facilityName} where the time clock is located if you cannot find it.`}
            </Text>
            {timeclockPin ? (
              <>
                <Text>To use the time clock, enter this code:</Text>
                <Title bold component="h1" textAlign="center">
                  {timeclockPin}
                </Title>
              </>
            ) : null}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ paddingBottom: 1 }}>
          <Stack spacing={1} direction="column" width="100%">
            <Button
              fullWidth
              variant="contained"
              onClick={() => {
                onContinue("main-dialog");
              }}
            >
              Let&apos;s do it!
            </Button>
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                helpModalState.openModal();
              }}
            >
              I need help
            </Button>
            <Button fullWidth variant="text" onClick={onCancel}>
              Go back
            </Button>
          </Stack>
        </DialogActions>
      </Dialog>
      <TimeclockRecordingHelpDetailsDialog
        modalState={helpModalState}
        onCancel={helpModalState.closeModal}
        onContinue={() => {
          helpModalState.closeModal();
          onContinue("help-dialog");
        }}
      />
    </>
  );
}
