import {
  Image,
  LoadingButton,
  ModalStatus,
  Text,
  Title,
  useModalState,
} from "@clipboard-health/ui-react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";

import timeclockBiometricComplianceImage from "../images/timeclock_biometric_compliance.png";

interface PolicyDialogProps {
  isLoading: boolean;
  onContinue: () => void;
  onCancel: () => void;
  facilityName: string;
  clockAction: "clock-in" | "clock-out";
  timeclockLocation: string;
  timeclockPin: string | undefined;
}

export function PolicyDialog(props: PolicyDialogProps) {
  const {
    isLoading,
    onContinue,
    onCancel,
    facilityName,
    clockAction,
    timeclockLocation,
    timeclockPin,
  } = props;

  const modalState = useModalState(ModalStatus.OPEN);

  return (
    <Dialog fullScreen open={modalState.modalIsOpen} onClose={onCancel}>
      <DialogTitle mt={2} mb={1} textAlign="left">
        Use the facility time clock
      </DialogTitle>
      <DialogContent>
        <Stack spacing={2} alignItems="stretch">
          <Image
            src={timeclockBiometricComplianceImage}
            alt="Time clock biometric compliance"
            width="100%"
          />
          <Text>
            You must take a photo of yourself using the facility&apos;s time clock before you can
            proceed with in-app clock {clockAction === "clock-in" ? "in" : "out"}.
          </Text>
          <Text>
            {facilityName} has a time clock located {timeclockLocation}.
          </Text>
          {timeclockPin ? (
            <>
              <Text>To use the time clock, enter this code:</Text>
              <Title bold component="h1" textAlign="center">
                {timeclockPin}
              </Title>
            </>
          ) : null}
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="column" spacing={0.5} width="100%">
          <LoadingButton fullWidth variant="contained" isLoading={isLoading} onClick={onContinue}>
            Continue
          </LoadingButton>
          <Button fullWidth onClick={onCancel}>
            Go Back
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
